<template>
	<div class="contact-item">
		<base-svg :name="icon"/>
		<base-font weight="bold" uppercase class="contact-item-title">{{title}}</base-font>
		<base-link :url="link" v-if="type !== 'text'" class="contact-item-content">
			{{content}}
		</base-link>
		<base-font v-else weight="medium" class="contact-item-content" :html="content"/>
	</div>
</template>
<script>
export default {
	props: {
		icon: String,
		title: String,
		type: {
			type: String,
			default: 'text'
		},
		content: String
	},
	computed: {
		link () {
			return this.type === 'mail' ? `mailto:${this.content}` : `tel:${this.content.replace(/\s/g, '')}`
		}
	}
}
</script>
<style lang="scss" scoped>
	.contact-item {
		display: grid;
		align-items: start;
		grid-template-columns: min-content max-content 1fr;
		gap: 2rem;
		place-items: center;
		@include media-breakpoint-up(lg) {
			align-items: center;
			gap: 1.5rem;
		}
		&:not(:last-of-type) {
			margin-bottom: 1rem;
		}

		:deep(.svg-icon) {
			height: 2rem;
			width: 2rem;
			grid-column: 1;
			@include media-breakpoint-up(lg) {
				height: 2.5rem;
				width: 2.5rem;
			}

			path {
				fill: $primary;
			}
		}
		&-title {
			min-width: 90px;
			grid-column: 2;

			@include media-breakpoint-up(lg) {
				min-width: 110px;
			}
		}
		&-content {
			grid-column: 3;
			max-width: max-content;
		}

	}

</style>
