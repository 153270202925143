<template>
	<section class="section">
		<base-container>
			<base-row>
				<base-col col="12" md="6" lg="7" xxl="6">
					<div class="bottom-wrapper">
						<div class="contact-details">
							<cms-text value="contact_page.questionTitle" class="contact-heading" :props="{variant: 'medium-header', tag: 'h2'}"/>
							<contact-item icon="email" type="mail" :title="translate.email" :content="settingsLangs.email"/>
							<contact-item icon="phone" type="phone" :title="translate.phone" :content="settingsLangs.phoneNumber"/>
						</div>
					</div>
				</base-col>
				<base-col col="12" md="6" lg="5" xxl="6">
				<cms-text value="contact_page.formTitle" class="contact-heading" :props="{variant: 'medium-header', tag: 'h2'}"/>
					<contact-form/>
				</base-col>
			</base-row>
		</base-container>
	</section>
</template>
<script>
import ContactItem from '~/website/front/components/molecules/ContactItem/ContactItem.vue'
import ContactForm from '~/website/front/components/sections/ContactForm/ContactForm.vue'
import { pageMixin } from '~/website/front/core/pages/page.mixin'

export default {
	components: { ContactForm, ContactItem },
	mixins: [
		pageMixin
	],
	computed: {
		translate () {
			return this.$app.translator.get('contact')
		},
		headquaters () {
			return `${this.settingsLangs.streetAddress},<br>${this.settingsLangs.postalCode} ${this.settingsLangs.addressLocality}`
		},
		settingsLangs () {
			return this.$app.settings.general.contact
		},
		pageLangs () {
			return this.$app.page.value.contact_page
		}
	}
}
</script>
<style lang="scss" scoped>
.section {
	padding: 1rem 0 2rem;
	@include media-breakpoint-up(md) {
		padding: 3rem 0;
	}
	@include media-breakpoint-up(lg) {
		padding-top: 0;
	}
	.contact-heading {
		margin-block: 2rem;
		@include media-breakpoint-up(lg) {
			margin-block: 3rem;
		}
		@include media-breakpoint-up(xl) {
			margin-block: 4rem 3rem;
		}
	}
	.company-details {
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		.logo-icon {
			height: auto;
			width: 15rem;
			margin: 2rem auto 2rem auto;
			@include media-breakpoint-up(lg) {
				width: 20rem;
				margin: 0 auto 1rem auto;
			}
			@include media-breakpoint-up(xl) {
				width: 25rem;
				margin: 0 auto 3rem auto;

			}
		}
	}
	.bottom-wrapper {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		position: relative;
		@include media-breakpoint-up(lg) {
			margin-top: 0;
		}
		@include media-breakpoint-up(xl) {
			flex-direction: row;
		}

		.contact-details {
			@include media-breakpoint-up(xl) {
				margin-right: 3rem;
			}
		}
	}
}
</style>
